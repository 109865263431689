import React from 'react'
import { useLocation } from 'react-router-dom'

import RouteWhiteList from '../../routes/routeWhiteList'

import Layout from '../Layout'

export default function Authentication() {
    const { pathname } = useLocation()
    const path = RouteWhiteList.has(pathname) ? RouteWhiteList.get(pathname) : <Layout />

    return (
        <>
            {path}
        </>
    )
}
