import React, { useEffect, useState } from 'react'
import './index.scss'

import Title from '../../Components/Title'
import ProductList from '../../Components/Product'
import Production from '../../Components/Production'

import http, { ROOT } from '../../utils/request'

export default function Product() {
  // const arrTab = ['GNSS', 'DSM', 'VMS', 'INMARSAT', 'IMU', 'RTLS', 'BALS']
  const arrId = [2, 5, 6, 7] // not have bottom
  let [type, setType] = useState('max')
  let [selected, setSelected] = useState(0)
  function tabClick(index) {
    setSelected(index)
    setType(arrId.includes(arrTab[index].id / 1) ? 'min' : 'max')
    getProduct(arrTab[index].id)
  }
  // const arrProduct = [
  //   { idx: 1 },
  //   { idx: 2 },
  //   { idx: 3 },
  // ]

  let [arrProduct, setArrProduct] = useState([])

  let [arrTab, setArrTab] = useState([])
  async function getTaps() {
    const res = await http.post('/api/index/getType')
    if (res.code === 2001) {
      let ctx = res.data
      setArrTab(ctx)
      getProduct(ctx[0]?.id)
    }
  }

  async function getProduct(type_id) {
    const res = await http.post('/api/index/getProduct', { type_id })
    if (res.code === 2001) {
      let ctx = res.data;
      console.log('998',res.data)
      setArrProduct(ctx)
    }
  }


  useEffect(() => {
    getTaps()

  }, [])

  return (
    <div className='product'>
      <div className='top-cont'>
        <img className='common-banner' src={require('../../static/solution/top-banner.png')} alt='#' />
        <div className='common-title'>Product Center</div>
      </div>
      <Title title='Product Center' />
      <div className='tab'>
        {arrTab.map((item, index) => {
          return (
            <div
              key={item.id}
              className={selected === index ? 'tab-item tab-item-selected' : 'tab-item'}
              onClick={() => tabClick(index)}
            >{item.name}</div>
          )
        })}
      </div>

      {/* {arrProduct.map((v, i) => <ProductList key={v.name} idx={i} data={v} />)} */}
      {arrProduct.map((v, i) => <Production key={v.name} type={type} idx={i} data={v} />)}

    </div>
  )
}
