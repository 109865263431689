import React from 'react'
import './index.scss'
import { RT, HN, BK } from '../../pages/Solution/ROLE'

export default function SolutionCard({ data }) {
  const Right = data.type & RT
  return (
    <div className='solution-card' id={data.icon.slice(0, 7)}>
      <div className='card-title'>
        <img className='title-icon' src={require(`../../static/solution/${data.icon}`)} alt='#' />
        <div className='title-text'>{data.title}</div>
      </div>
      <div className='card-banner' style={{ flexDirection: Right ? 'row' : 'row-reverse' }}>
        <img className='banner-item' src={require(`../../static/solution/${data.banner}`)} alt='#' />
        <div className='banner-info'>
          <div className='info-title' style={{ flexDirection: Right ? 'row-reverse' : 'row' }}>
            <img className={Right ? 'info-title-icon m-l-20' : 'info-title-icon m-r-20'} src={require('../../static/solution/cor.png')} alt='#' />
            <div className='info-title-text'>SYSTEM INTRODUCTION</div>
          </div>
          <div className='info-title-cont'>{data.introduction}</div>
        </div>
      </div>
      <div className='card-list'>
        <div className='info-title' style={{ flexDirection: Right ? 'row-reverse' : 'row' }}>
          <img className={Right ? 'info-title-icon m-l-20' : 'info-title-icon m-r-20'} src={require('../../static/solution/cor.png')} alt='#' />
          <div className='info-title-text'>SYSTEM CHARACTERISTICS</div>
        </div>
        {data.detail.map((item, index) => (
          <div key={`${item.content}${index}`} className='list-item'>
            {!(item.ROLE & BK) ? (
              <>
                {item.ROLE & HN && <span className='num'>{item.num}. </span>}
                <span>{item.content}</span>
              </>
            ) : <></>}
          </div>
        ))}
      </div>
    </div>
  )
}
