import React, { useState, useEffect } from 'react'
import './index.scss'
import bg from '../../static/connect-bg.png'

import Title from '../../Components/Title'

import http, { ROOT } from '../../utils/request'

export default function Connect() {

  let [info, setInfo] = useState({})

  async function getData() {
    const res = await http.post('/api/index/showContent')
    if (res.code === 2001) {
      let ctx = res.data?.contact || {}
      ctx.addressimage = ROOT + ctx.addressimage
      setInfo(ctx)
    }
  }

  useEffect(() => {
    getData()
  }, []);

  return (
    <div className='connect'>
      <div className='top-cont'>
        <img className='common-banner' src={bg} alt='#' />
        <div className='common-title'>Contact us</div>
      </div>

      <Title title='Contact us' />

      <div className='map'>
        <img className='map-img-bg' src={info.addressimage} alt='#' />
       
      </div>

      <div className='addressbox'>

        <div className='addressbox-item'>
          <div>
            <div className='title'>Address:</div>
            <div className='cont1'>Create Technologies Pte Ltd10 Ubi Cres,#03-58,Ubi Techpark(Lobby C),Singapore 408564. </div>
          </div>
        </div>

        <div className='addressbox-item'>
          <div>
            <div className='title'>Contact:</div>
            {/* <div className='cont'>{info.lianxiren}</div> */}
            <div className='cont'>+65 9661 4875</div>
          </div>

          <div>
            <div className='title'>How to find us:</div>
            {/* <div className='cont'>{info.address}</div> */}
            <div className='cont'>Create Technologies Pte Ltd<br />
              10 Ubi Cres,#03-58,Ubi Techpark(Lobby C),Singapore 408564</div>
          </div>
          <div>
            <div className='title'>Email address:</div>
            {/* <div className='cont'>{info.email}</div> */}
            <div className='cont'>hszn@norkern.com</div>
          </div>
          <div>
            <div className='title'>whatsapp:</div>
            {/* <div className='cont'>{info.phone}</div> */}
            <div className='cont'>189-1541-9064</div>
          </div>
        </div>

        <div className='share'>
          <a target='_blank' rel="noreferrer" href='https://www.linkedin.com/company/create-tech/?viewAsMember=true'>
            <img className='share-img' src={require('../../static/s-1.png')} alt='#' />
          </a>
          {/* <img className='share-img' src={require('../../static/s-2.png')} alt='#' />
          <img className='share-img' src={require('../../static/s-3.png')} alt='#' />
          <img className='share-img' src={require('../../static/s-4.png')} alt='#' />
          <img className='share-img' src={require('../../static/s-5.png')} alt='#' /> */}

        </div>

      </div>

      <div className='form'>
        <img className='left-img' src={require('../../static/aaa.png')} alt='#' />
        <div className='formbox'>
          <div className='title'>Leave a message online</div>
          <div className='ipt-1'>
            <div className='ipt-1-item'>
              <div className='ipt-title'>Customer name</div>
              <input placeholder='Please enter your name' className='ipt-item'></input>
            </div>
            <div className='ipt-1-item'>
              <div className='ipt-title'>Contact</div>
              <input placeholder='Please enter your name' className='ipt-item'></input>
            </div>
            <div className='ipt-1-item'>
              <div className='ipt-title'>Email</div>
              <input placeholder='Please enter your email' className='ipt-item'></input>
            </div>
          </div>

          <div className='ipt-1'>
            <div className='ipt-1-item'>
              <div className='ipt-title'>Massage</div>
              <textarea placeholder='Please enter your massage' className='ipt-item ipt-textarea'></textarea>
            </div>
          </div>

          <div className='btn-submit'>
            Submit
          </div>

        </div>
      </div>

    </div>
  )
}
