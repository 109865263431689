import React, { useState, useEffect } from 'react'
import './index.scss'
import bg from '../../static/news/head.png'

import Title from '../../Components/Title'

import http, { ROOT } from '../../utils/request'
import { useLocation } from 'react-router-dom'

export default function Connect() {

  let [txt, setTxt] = useState('')

  const location = useLocation()

  async function getData() {

    const { id } = location.state

    const res = await http.post('/api/index/getArticleContent', { article_id: id })
    if (res.code === 2001) {
      let txt = res.data.articlecontent
      setTxt(txt)
    }
  }

  useEffect(() => {
    getData()
  }, []);

  return (
    <div className='news'>
      <div className='top-cont'>
        <img className='common-banner' src={bg} alt='#' />
        <div className='common-title'>——    Information Centre</div>
      </div>

      {/* <Title title='Information Centre' /> */}

      <div className='detail' dangerouslySetInnerHTML={{ __html: txt }}></div>


    </div>
  )
}
