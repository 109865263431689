/* 
  break line: 0001
  has number: 0010
  pure text:  0100
*/

export const BK = 0b0001
export const HN = 0b0010
export const PT = 0b0100

export const LT = 0b00
export const RT = 0b01
