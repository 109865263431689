import { BK, HN, LT, RT } from './ROLE'
/* 
      { ROLE: BK }, 换行
      { ROLE: HN, num: 1, content: '', }, 带序号的行
      { ROLE: PT, content: '', }, 纯文本行

*/
const arrContent = [
  {
    type: LT, // 文本位置
    icon: 'icon-01.jpg',
    title: 'Fleet Management System',
    banner: 'banner-01.png',
    introduction: 'Use our reliable vehicle tracking sensor based on multi-system GNSS+IMU technology, you will be able to track your vehicles accurately (traffic lane level precision) and reliably in dense urban areas, inside tunnels and underground/indoor car-parks. The solution also includes possibilities to integrate with DSM (Driver Status Monitoring) devices to better manage the fleet for efficiency and safety. ',
    detail: [
      { ROLE: HN, num: 1, content: `Visual display of the vehicle's real-time position`, },
      { ROLE: HN, num: 2, content: `Automatically generate all kinds of system information and safety warning information`, },
      { ROLE: HN, num: 3, content: `Graphical analysis of work area utilization and performance of selected vehicles`, },
      { ROLE: HN, num: 4, content: `Trace the history of the selected vehicle`, },
      { ROLE: HN, num: 5, content: `Off-course alarm`, },
      { ROLE: HN, num: 6, content: `Automatic generation of vehicle aggregation analysis and stall timeout alarm `, },
    ]
  },
  {
    type: RT,
    icon: 'icon-02.jpg',
    title: 'Vessel Monitoring System',
    banner: 'banner-02.png',
    introduction: 'With our cm-level GNSS positioning sensor, the Vessel Monitoring System is able to accurately and indisputably measure the time duration in which a vessel is at the berth thus triggering charging for the service of utilizing the berth. The solution integrates with feul level monitoring, cabin status monitoring to provide full tracking of vessels during its daily operations for fraud prevention, efficiency improvement and safety. ',
    detail: [
      { ROLE: HN, num: 1, content: `Visual display of the vehicle's real-time position`, },
      { ROLE: HN, num: 2, content: `Automatically generate all kinds of system information and safety warning information`, },
      { ROLE: HN, num: 3, content: `Enter danger area warning`, },
      { ROLE: HN, num: 4, content: `Graphical analysis of work area utilization and performance of selected vehicles`, },
      { ROLE: HN, num: 5, content: `Trace the history of the selected vehicle`, },
      { ROLE: HN, num: 6, content: `Off-course alarm`, },
      { ROLE: HN, num: 7, content: `Automatic generation of vehicle aggregation analysis and stall timeout alarm `, },
    ]
  },
  {
    type: LT,
    icon: 'icon-03.png',
    title: 'Deformation Monitoring System',
    banner: 'banner-03.png',
    introduction: 'Use our reliable vehicle tracking sensor based on multi-system GNSS+IMU technology, you will be able to track your vehicles accurately (traffic lane level precision) and reliably in dense urban areas, inside tunnels and underground/indoor car-parks. The solution also includes possibilities to integrate with DSM (Driver Status Monitoring) devices to better manage the fleet for efficiency and safety. ',
    detail: [
      { ROLE: HN, num: 1, content: `The system can collect and process the deformation data continuously, automatically and in real time.`, },
      { ROLE: HN, num: 2, content: `Analysis, process line drawing, judgment, prediction, report printing and Web publishing.`, },
      { ROLE: HN, num: 3, content: `The warning value is set for the deformed body to realize the over-limit alarm function.`, },
      { ROLE: HN, num: 4, content: `Visualizes the real-time deformation monitoring.`, },
      { ROLE: HN, num: 5, content: `The system can collect and process the deformation data continuously, automatically and in real time.`, },
      { ROLE: HN, num: 6, content: `According to the processing data, the control center realizes analysis, process line drawing, judgment, prediction, report printing and Web publishing.`, },
    ]
  },
  {
    type: RT,
    icon: 'icon-04.png',
    title: 'Construction Site Safety Solution',
    banner: 'banner-04.png',
    introduction: 'Integrating location tracking and A/V sensors and sound alarms into a standard safety helmet, transforming the most popular worksite PPE (personal protection equipment) into a smart safety solution so that work site safety can be digitally enhanced. It allows on-site safety supervisors as well as remote safety centers to have full work-site safety situational awareness, to automate safety alerts through geo-fencing thus reducing accidences and safety violations. The system also records personnel tracks and events, for post morten analysis in case of safety accident or violations.',
    detail: [
      { ROLE: HN, num: 1, content: `Obtain accurate location information of personnel in real time.`, },
      { ROLE: HN, num: 2, content: `Dynamic display of the movement of people under the tower crane, loading and unloading area, container stacking area.`, },
      { ROLE: HN, num: 3, content: `Integrated heat map analysis of employee distribution and statistics of employee activity mileage to optimize personnel scheduling.`, },
      { ROLE: HN, num: 4, content: `According to the safety requirements of the port, set up various types of electronic fences and working areas, and formulate personnel safety strategies.`, },
      { ROLE: HN, num: 5, content: `Monitor the implementation of safety policies and correct personnel violations.`, },
      { ROLE: HN, num: 6, content: `Provide a variety of alarm means to ensure personal safety of personnel, reduce safety expenditure.`, },
    ]
  },
  {
    type: LT,
    icon: 'icon-05.jpg',
    title: 'Safety Supervision and Communication System for Large Groups',
    banner: 'banner-05.png',
    introduction: 'Lightweight personal tracking and communication device in the form factor of a badge, conveniently worn using lanyard. Equipped with a check-in button to report safety and location, two-way voice communication capability for reaching to SOS centre or pre-programmed numbers for assistance. Long battery life and reusable from group to group. ',
    detail: [
      { ROLE: HN, num: 1, content: `HELP ASSIST Request help from your colleagues at your  location or ask for help from professional assistance organizations.`, },
      { ROLE: BK },
      { ROLE: HN, num: 2, content: `TRACK Automatically send and save your real-time location and allow contacts to track your progress using SIRUS® MSS portal No movement detection CHECK IN Let contacts know where you are and that you’re okay with a pre-programmed message. `, },
      { ROLE: BK },
      { ROLE: HN, num: 3, content: `CUSTOM MESSAGE Play the broadcast message. S.O.S. In an emergency, to send an S.O.S. message to a pre-determined designation Require a 4G SIM card for connectivity SIRUS® MSS portal`, },

    ]
  },
  {
    type: RT,
    icon: 'icon-06.jpg',
    title: ' School Student Care System',
    banner: 'banner-06.png',
    introduction: 'Lightweight personal tracking device tailored for application for school children safety in the form factor of a badge, conveniently worn using a lanyard. It allows the school to ascertain that the children is inside the school or outside, allows the parents to ascertain that the child is en-route or at home. When abnormal location or situation is detected, alerts can be generated to both school and parent. ',
    detail: [
      { ROLE: HN, num: 1, content: `TRACK Automatically send and save children's location and allow contacts to track their progress using SIRUS® MSS portal Abnormal Location alarm Fall or No movement detection`, },
      { ROLE: BK },
      { ROLE: HN, num: 2, content: ` CHECK IN  Let parents know where children is and that his/she is okay with a pre-programmed message.`, },
      { ROLE: BK },
      { ROLE: HN, num: 3, content: `CUSTOM MESSAGE Play the broadcast message. S.O.S. In an emergency, send an S.O.S. with children’s location to a pre-determined designation Require a 4G SIM card for connectivity SIRUS® MSS portal`, },
    ]
  },
  {
    type: LT,
    icon: 'icon-07.jpg',
    title: 'Equipment Tracking System',
    banner: 'banner-07.png',
    introduction: 'Lightweight personal tracking and communication device in the form factor of a badge, conveniently worn using lanyard. Equipped with a check-in button to report safety and location, two-way voice communication capability for reaching to SOS centre or pre-programmed numbers for assistance. Long battery life and reusable from group to group. ',
    detail: [
      { ROLE: HN, num: 1, content: `Track the transportation process of equipment from factory to construction site, which can be carried out across borders`, },
      { ROLE: BK },
      { ROLE: HN, num: 2, content: `TRACK Automatically send and save your real-time location and allow contacts to track your progress using SIRUS® MSS portal No movement detection CHECK IN Let contacts know where you are and that you’re okay with a pre-programmed message. `, },

    ]
  },
  {
    type: RT,
    icon: 'icon-08.jpg',
    title: 'Firefighter Tracking System',
    banner: 'banner-08.png',
    introduction: 'A custom-design and made portable system for on-site commander to track each and every fire-fighter sent into a multi-story fire site. It does not rely on any infrastructure in the target building nor having access to its floor map. The system allows a fast 3D model generation on the fly, giving the commander a visual situation-awareness on the locations of each fire-fighter sent into the building. The graphical situation can be remotely accessed from regional command centre. The body-worn device by each fire-fighter can detect immobilization, fall of the soldier, and also support audio and visual alerts and commands from the on-site commander. The operation can be played back from post event analysis and training and improvement purposes. ',
    detail: [
      { ROLE: HN, num: 1, content: `Stable user tracking technology Different environments Different users Different device usage`, },
      { ROLE: BK },
      { ROLE: HN, num: 2, content: `Intelligent map matching technology Significantly increase positioning accuracy with the help of map structure Indoor positioning accuracy 0.5m Indoor positioning accuracy of 3 meters`, },
      { ROLE: BK },
      { ROLE: HN, num: 3, content: `Intelligent map matching technology Significantly increase positioning accuracy with the help of map structure  Indoor positioning accuracy 0.5m Indoor positioning accuracy of 3 meters`, },
    ]
  },
  {
    type: LT,
    icon: 'icon-09.jpg',
    title: 'UWB Anti-Collision System  ',
    banner: 'banner-09.png',
    introduction: 'This proximity alert solution provides moving objects such as fork-lift, cranes and personnel to be aware of each other when they come too close to each other. It works as a stand-alone system without the complexity of communication networks, software portal etc. Just beacons (for the moving machinery) and tags (for the people around it). The distance of alert can be pre-set to the desirable safety level. When the equipped machineries and people come within the distance, audio and visual alerts are triggered to the operator and the personnel nearby to avoid accidents.',
    detail: [
      { ROLE: HN, num: 1, content: `Provide high precision ranging between vehicles and personnel.`, },
      { ROLE: HN, num: 2, content: `Provide real-time safety alerts for drivers and staff to prevent accidents`, },
      { ROLE: HN, num: 3, content: `Support 1D/2D/3D positioning methods, real-time monitoring range of 100m radius`, },
      { ROLE: HN, num: 4, content: `Insensitive to target moving speed, suitable for a variety of work scenarios`, },
      { ROLE: HN, num: 5, content: `Long battery life huge system capacity, supporting up to 6500 tags`, },

    ]
  },
  {
    type: RT,
    icon: 'icon-10.jpg',
    title: 'UWB Real-time Tracking System',
    banner: 'banner-10.png',
    introduction: 'A custom-design and made portable system for on-site commander to track each and every fire-fighter sent into a multi-story fire site. It does not rely on any infrastructure in the target building nor having access to its floor map. The system allows a fast 3D model generation on the fly, giving the commander a visual situation-awareness on the locations of each fire-fighter sent into the building. The graphical situation can be remotely accessed from regional command centre. The body-worn device by each fire-fighter can detect immobilization, fall of the soldier, and also support audio and visual alerts and commands from the on-site commander. The operation can be played back from post event analysis and training and improvement purposes. ',
    detail: [
      { ROLE: HN, num: 1, content: `Indoor and outdoor location data access`, },
      { ROLE: HN, num: 2, content: ` Trajectory tracking and heat map analysis`, },
      { ROLE: HN, num: 3, content: `Customizable interface with customer's existing systems`, },
      { ROLE: HN, num: 4, content: `Centimeter precision positioning algorithm`, },
      { ROLE: HN, num: 5, content: `Low latency of Position data distribution`, },
      { ROLE: HN, num: 6, content: `Cloud based platforms to adapt to different application scenarios`, },
      { ROLE: HN, num: 7, content: `Highly cost effective`, },
    ]
  },
]

export default arrContent