import React, { useState, useEffect } from 'react'
import moment from 'moment'
import './index.scss'
import bg from '../../static/news/head.png'

import Title from '../../Components/Title'

import http, { ROOT } from '../../utils/request'
import { useNavigate } from 'react-router-dom'

export default function Connect() {

  let [list, setList] = useState([])
  let [arrPage, setArrPage] = useState([])
  let [params, setParams] = useState({ pageNum: 1, pageSize: 7, isMore: true })
  let reg = /(<.+?>)|(<\/.+?>)/g
  async function getData() {
    const res = await http.post('/api/index/getArticle', params)
    if (res.code === 2001) {
      let list = res.data.list.map(item => {
        return {
          title: item.title,
          id: item.id,
          coverimage: ROOT + item.coverimage,
          time: moment(item.article_time * 1000).format("MM-DD"),
          describe: item.describe?.replace(reg, '')
        }
      })



      params.isMore = res.data.total !== list.length + (params.pageNum - 1) * params.pageSize

      let totalPage = Math.ceil(res.data.total / params.pageSize)

      arrPage = new Array(totalPage).fill(0)
      setArrPage(arrPage)
      setParams(params)

      setList(list)
    }
  }

  useEffect(() => {
    getData()
  }, [])

  let getNewParams = pageNum => {
    if (!pageNum || pageNum === params.pageNum || pageNum < 1 || pageNum > arrPage.length) return
    params.pageNum = pageNum
    setParams(params)
    getData()
  }

  const navigate = useNavigate()

  let getDetail = id => {
    navigate('/detail', {
      state: { id }
    })
  }

  return (
    <div className='news'>
      <div className='top-cont'>
        <img className='common-banner' src={bg} alt='#' />
        <div className='common-title'>——    Information Centre</div>
      </div>

      <Title title='Information Centre' />

      <div className='list'>
        {list.map((item, index) => (
          <div key={item.id} onClick={() => { getDetail(item.id) }} className='list-item'>
            <div className='list-img-box'>
              <img alt='' src={item.coverimage} className='list-img' />
              <div className={index === -1 ? 'list-time ' : 'list-time'}>{item.time}</div>
            </div>
            <div className='list-info'>
              <div className='list-title'>{item.title}</div>
              <div className='list-cont'>{item.describe}</div>
            </div>
          </div>
        ))}
      </div>

      <div className='page-count'>
        <div className='count'>
          {arrPage.map((item, index) => (
            <div key={index} onClick={() => { getNewParams(index + 1) }} className={params.pageNum === index + 1 ? 'count-item count-item-selected' : 'count-item'}>{index + 1}</div>
          ))}
        </div>
        <div className='go-count'>
          <div onClick={() => { getNewParams(params.pageNum - 1) }} className={params.pageNum > 1 ? 'count-item count-item-selected' : 'count-item'}>
            {`<`}
            {/* <img alt='' src={require('../../static/news/arrow-left.png')} /> */}
          </div>
          <div onClick={() => { getNewParams(params.pageNum + 1) }} className={params.pageNum < arrPage.length ? 'count-item count-item-selected' : 'count-item'}>
            {`>`}
            {/* <img alt='' src={require('../../static/news/arrow-right.png')} /> */}
          </div>
        </div>
      </div>

    </div>
  )
}
