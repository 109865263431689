import axios from "axios";

const server = axios.create({
  baseURL: '/index.php',
  // baseURL: 'https://some-domain.com/api/',
  timeout: 10000,
})

server.interceptors.request.use(function (config) {
  // Do something before request is sent
  return config;
}, function (error) {
  // Do something with request error
  return Promise.reject(error);
});

// Add a response interceptor
server.interceptors.response.use(function (response) {
  // Any status code that lie within the range of 2xx cause this function to trigger
  // Do something with response data
  return response.data;
}, function (error) {
  // Any status codes that falls outside the range of 2xx cause this function to trigger
  // Do something with response error
  return Promise.reject(error);
});

export const ROOT = 'http://gw.zwrjkj.cn'

export default server
