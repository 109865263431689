import React, { useState, useRef, useEffect } from 'react'
import './index.scss'

import { Swiper, SwiperSlide } from "swiper/react";

import http, { ROOT } from '../../utils/request'
import { useNavigate } from 'react-router-dom'

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import { Link } from 'react-router-dom';

// import required modules
import { Navigation, Autoplay } from "swiper";

import Title from '../../Components/Title'
import Client from '../../Components/Clinet'

export default function Index() {
  let [selected, setSelected] = useState(-1)
  const arr = [
    { title: 'Fleet Management System', cont: 'With our cm-level GNSS positioning sensor, the Vessel Monitoring System is able to accurately and indisputably measure the time duration in which a vessel is at the berth thus triggering charging for the service of utilizing the berth.' },
    { title: 'Vessel Monitoring System', cont: 'With our cm-level GNSS positioning sensor, the Vessel Monitoring System is able to accurately and indisputably measure the time duration in which a vessel is at the berth thus triggering charging for the service of utilizing the berth.' },
    { title: 'Deformation Monitoring System', cont: 'Using our innovative mm-level monitoring sensor and back-end software algorithms, the solution provides long term continuous monitoring of structures such as bridges, high-rise buildings for deformation.' },
    { title: 'Construction Site Safety Solution', cont: 'Integrating location tracking and A/V sensors and sound alarms into a standard safety helmet, transforming the most popular worksite PPE (personal protection equipment) into a smart safety solution so that work site safety can be digitally enhanced.' },
    { title: 'Safety Supervision and Communication System for Large Groups', cont: 'Safety Supervision and Communication System for Large Groups' },
    { title: 'School Student Care System', cont: 'Lightweight personal tracking device tailored for application for school children safety in the form factor of a badge, conveniently worn using a lanyard. It allows the school to ascertain that the children is inside the school or outside, allows the parents to ascertain that the child is en-route or at home.' },
    { title: 'Equipment Tracking System', cont: 'Equipment ranging from bulldozers, tractors to trolleys and fork-lifts need to be tracked so that they can be better managed against theft, mis-placement as well as improving utilization and sharing.' },
    { title: 'Firefighter Tracking System', cont: 'A custom-design and made portable system for on-site commander to track each and every fire-fighter sent into a multi-story fire site. It does not rely on any infrastructure in the target building nor having access to its floor map.' },
    { title: 'UWB Real-time Tracking System', cont: 'The Ultra Wide Band (UWB) positioning solution provides high-accuracy, low-latency indoor position and location of moving objects. This is suitable for applications that derive contextual information through monitoring interactions between tracked objects and their environments.' },
  ]


  let [arrBanner, setArrBanner] = useState([])


  let [bgColor, setBgColor] = useState(true)
  let timer = null
  function fn() {
    if (timer) return
    timer = setTimeout(() => {
      clearInterval(timer)
      setBgColor(!bgColor)
      fn()
    }, 3000)
  }
  fn()

  const couterRef = useRef();

  async function getBanner() {
    const res = await http.post('/api/index/showContent')
    if (res.code === 2001) {
      const arr = res.data.lunbo.map(v => ROOT + v.lunboimage)
      setArrBanner(arr)
    }
  }

  useEffect(() => {
    couterRef.current.swiper.autoplay.stop()
    setTimeout(() => {
      couterRef.current.swiper.autoplay.start()
    }, 1000)
    getBanner()
  }, [])
  
  const navigate = useNavigate()
  let getDetail = id => {
    navigate('/detail', {
      state: { id }
    })
  }


  return (
    <div className='index'>
      <div className='top'>
        {/* <img className='top-bg' src={require('../../static/index-bg.png')} alt='#' /> */}

        <Swiper
          className='top'
          loop={true}
          autoplay={{
            delay: 3000,
            disableOnInteraction: false,
          }}
          navigation={{
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
          }}
          modules={[Navigation, Autoplay]}
        // onSlideChange={() => console.log('slide change')}
        // onSwiper={(swiper) => console.log(swiper)}
        >
          {arrBanner.map(item => {
            return (
              <SwiperSlide key={item}>
                <img className='top-bg' src={item} alt='#' />
              </SwiperSlide>
            )
          })}
          {/* <SwiperSlide>
            <img className='top-bg' src={require('../../static/index-1.jpg')} alt='#' />
          </SwiperSlide>
          <SwiperSlide>
            <img className='top-bg' src={require('../../static/index-2.jpg')} alt='#' />
          </SwiperSlide> */}
        </Swiper>
        <img className='prev swiper-button-prev' src={require('../../static/prev.png')} alt='#' />
        <img className='next swiper-button-next' src={require('../../static/next.png')} alt='#' />
        <div className='top-line-bg'>
          {/* <img className='top-line-bg-box' src={require('../../static/index-line-bg.png')} alt='#' /> */}
          <Swiper
            ref={couterRef}
            loop={true}
            autoplay={{
              delay: 5000,
              disableOnInteraction: false,
            }}
            modules={[Autoplay]}
            className='top-line-bg-box'
          >
            {/* <SwiperSlide>
              <div className='top-line-text'>
                VESSEL <br /> MONITARING
              </div>
              <div className='top-line-btn'>
                Click Here
              </div>
            </SwiperSlide> */}
          </Swiper>
        </div>
      </div>


      <Title title='Who Is Create' />

      <div className='craete'>
        <div className='text-cont'>Create aims to be a pioneer and technology leader in high-precision positioning and tracking systems and building deformation monitoring systems. Building a platform for high-precision location information and services for a variety of application scenarios, with our own proprietary algorithms and products to provide a total solution based on GNSS, UWB or inertial navigation technology, no matter where you are.</div>
        <img className='dot-img' src={require('../../static/sh2.png')} alt='#' />
        <img className='pian-img' src={require('../../static/pain.png')} alt='#' />
		 <img className='pian-img1' src={require('../../static/pain.png')} alt='#' />
        <img className='main-img' src={require('../../static/main.png')} alt='#' />

      </div>

      <Title title='Solustions' />

      <div className='solution'>
        {arr.map((item, index) => (
          <div key={index} className='solution-item' onMouseOver={() => { setSelected(index) }} onMouseOut={() => { setSelected(-1) }}>
            {selected === index ? (
              <img className='so-img' src={require(`../../static/so-${index + 1}-ed.png`)} alt='#' />
            ) : (
              <img className='so-img' src={require(`../../static/so-${index + 1}.png`)} alt='#' />
            )}
            <div style={{ color: selected === index ? '#FFFFFF' : '' }} className='title'>{item.title}</div>
            <div style={{ color: selected === index ? '#FFFFFF' : '' }} className='cont'>{item.cont}</div>
          </div>
        ))}
      </div>

      <Title title='Why choose Create' />
      <div className='choose-create'>
        <div style={{ backgroundColor: bgColor ? '#6b6b6b' : '#ea9c4b' }} className='cc-item'>
          <div className='order'>
            1
          </div>
          <div className='content'>
            CREATE's products and solutions are targeted to solve customers' problems.
          </div>
        </div>
        <div style={{ backgroundColor: bgColor ? '#ea9c4b' : '#6b6b6b' }} className='cc-item'>
          <div className='order'>
            2
          </div>
          <div className='content'>
            The TECHNICAL advantages of CREATE's products and solutions can solve the positioning and tracking problems in various complex environments.
          </div>
        </div>
        <div style={{ backgroundColor: bgColor ? '#6b6b6b' : '#ea9c4b' }} className='cc-item'>
          <div className='order'>
            3
          </div>
          <div className='content'>
            CREATE's solutions can reduce safety risks, enhance efficiency and improve productivity.
          </div>
        </div>
        <div style={{ backgroundColor: bgColor ? '#ea9c4b' : '#6b6b6b' }} className='cc-item'>
          <div className='order'>
            4
          </div>
          <div className='content'>
            The CREATE's solutions is conducive to widespread applications and cost effective.
          </div>
        </div>
      </div>

      <Title title='News update' />

      <div className='news'>
        <div className='news-item' onClick={() => { getDetail(10) }}>
          <img className='news-img' src={require('../../static/news-1.jpg')} alt='' />
          <div className='news-tit'>RTLS Indoor tracking System</div>
          <div className='news-txt'>Create and Halliburton formally signed a cooperation agreement to develop and deploy an automated production line material monitoring and tracking system</div>
        </div>
        <div className='news-item' onClick={() => { getDetail(11) }}>
          <img className='news-img' src={require('../../static/news-2.png')} alt='' />
          <div className='news-tit'>Worksite Safety Solution</div>
          <div className='news-txt'>Create and China Railway First Group formally launched the implementation of construction site safety monitoring system</div>
        </div>
        <div className='news-item' onClick={() => { getDetail(12) }}>
          <img className='news-img' src={require('../../static/news-3.png')} alt='' />
          <div className='news-tit'> Fleet Management System</div>
          <div className='news-txt'>Create and UPS reached a memorandum of understanding on the development and deployment of a fleet management system</div>
        </div>

        {/* <div className='news-item' onClick={() => { getDetail(12) }}>
          <img className='news-img' src={require('../../static/news-3.png')} alt='' />
          <div className='news-tit'> Fleet Management System</div>
          <div className='news-txt'>Create and UPS reached a memorandum of understanding on the development and deployment of a fleet management system</div>
        </div> */}

      </div>
	<div className='dot'>
	  <Link to='/news'>
    <img className='go-img' src={require('../../static/go.png')} alt='' />
	  </Link>
	  {/* <div></div>
	  <div></div>
	  <div></div> */}
	</div>

      <Client />
      <div className='zhan'></div>

    </div>
  )
}
