import Authentication from "../pages/Authentication";
import Index from "../pages/Index";
import About from "../pages/About";
import Connect from "../pages/Connect";
import Product from '../pages/Product'
import Solution from '../pages/Solution'
import News from '../pages/News'
import Detail from '../pages/News/detail'

const routes =  [
  {
    path: '/*',
    element: <Authentication />,
    children: [
      {
        path: 'index',
        element: <Index />
      },
      {
        path: 'about',
        element: <About />
      },
      {
        path: 'connect',
        element: <Connect />
      },
      {
        path: 'solution',
        element: <Solution />
      },
      {
        path: 'product',
        element: <Product />
      },
      {
        path: 'news',
        element: <News />
      },
      {
        path: 'detail',
        element: <Detail />
      },
    ]
  },
]

export default routes
