import React from 'react'
import './index.scss'
import Title from '../Title'

import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Autoplay, FreeMode } from "swiper";
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/pagination";

export default function Client() {
    return (
        <div className='clinet-container'>
            <Title title='Clients' />

            <div className='client'>
                <img className='before swiper-button-prev' src={require('../../static/before.png')} alt='' />

                <Swiper
                    className='client-box'
                    slidesPerView={3}
                    spaceBetween={30}
                    freeMode={true}
                    loop={true}
                    autoplay={{
                        delay: 3000,
                        disableOnInteraction: false,
                    }}
                    navigation={{
                        nextEl: '.swiper-button-next',
                        prevEl: '.swiper-button-prev',
                    }}
                    modules={[Navigation, Autoplay, FreeMode]}
                >
                    <SwiperSlide className='swiper-side-item-class'>
                        <img  className='client-img' src={require('../../static/cl-1.png')} alt='' />
                    </SwiperSlide>
                    <SwiperSlide className='swiper-side-item-class'>
                        <img  className='client-img' src={require('../../static/cl-2.png')} alt='' />
                    </SwiperSlide>
                    <SwiperSlide className='swiper-side-item-class'>
                        <img  className='client-img' src={require('../../static/cl-3.png')} alt='' />
                    </SwiperSlide>
                    <SwiperSlide className='swiper-side-item-class'>
                        <img  className='client-img' src={require('../../static/cl-4.png')} alt='' />
                    </SwiperSlide>
                    <SwiperSlide className='swiper-side-item-class'>
                        <img  className='client-img' src={require('../../static/cl-5.png')} alt='' />
                        
                    </SwiperSlide>
                    <SwiperSlide className='swiper-side-item-class'>
                        <img className='client-img' src={require('../../static/cl-6.png')} alt='' />    
                    </SwiperSlide>
                    <SwiperSlide className='swiper-side-item-class'>
                        <img  className='client-img' src={require('../../static/cl-7.png')} alt='' />
                        
                    </SwiperSlide>
                    <SwiperSlide className='swiper-side-item-class'>
                        <img  className='client-img' src={require('../../static/cl-8.png')} alt='' />
                        
                    </SwiperSlide>
                    <SwiperSlide className='swiper-side-item-class'>
                        <img  className='client-img' src={require('../../static/cl-9.png')} alt='' />
                        
                    </SwiperSlide>
                </Swiper>
                <img className='after swiper-button-next' src={require('../../static/after.png')} alt='' />
            </div>
        </div>
    )
}
