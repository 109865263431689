import React, { useEffect, useRef, useState } from 'react'
import './index.scss'

import { motion, useAnimationControls, Reorder } from "framer-motion"

const MyComponent = () => {
  const [items, setItems] = useState([0, 1, 2, 3])

  return (
    <Reorder.Group values={items} onReorder={setItems}>
      {items.map(item => (
        <Reorder.Item key={item} value={item}>
          {item}
        </Reorder.Item>
      ))}
    </Reorder.Group>
  )
}

const App = () => {
  return (
    <div className="App">
      <header className="App-header">
        <h1>我的看板</h1>
      </header>
      <main className="kanban-board">
        <section className="kanban-column"></section>
        <section className="kanban-column"></section>
        <section className="kanban-column"></section>
      </main>
    </div>
  )
}


export default function Animate() {


  return (
    <div className='animate'>
      <div>
        <MyComponent />
        <App />
      </div>
      <div>
      </div>
    </div>
  )
}
